import request from '@/utils/request'

const MemberApi = {
    MemberList: '/admin/Member/memberList', // 客户列表
    MemberInfo: '/admin/Member/memberInfo', // 客户详情
    MemberAdd: "/admin/Member/memberAdd", // 客户添加
    MemberEdit: "/admin/Member/memberEdit", // 客户编辑
    MemberDelete: "/admin/Member/memberDele", // 客户删除
    MemberBalance: "/admin/Member/memberBalance",  // 客户消费记录
    getMerchantInfo:"/admin/member/getMerchantInfo",
    rejectMerchant:'/admin/member/rejectMerchant',
    passMerchant:'/admin/member/passMerchant'
}
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
 export function rejectMerchant(parameter) {
    return request({
        url: MemberApi.rejectMerchant,
        method: 'post',
        data: parameter
    })
}
export function passMerchant(parameter) {
    return request({
        url: MemberApi.passMerchant,
        method: 'post',
        data: parameter
    })
}
 export function getMerchantInfo(parameter) {
    return request({
        url: MemberApi.getMerchantInfo,
        method: 'get',
        params: parameter
    })
}
export function MemberList(parameter) {
    return request({
        url: MemberApi.MemberList,
        method: 'get',
        params: parameter
    })
}

export function MemberInfo(parameter) {
    console.log(parameter)
    return request({
        url: MemberApi.MemberInfo,
        method: 'get',
        params: parameter
    })
}

export function MemberAdd(parameter) {
    return request({
        url: MemberApi.MemberAdd,
        method: 'post',
        params: parameter
    })
}

export function MemberEdit(parameter) {
    return request({
        url: MemberApi.MemberEdit,
        method: 'post',
        data: parameter
    })
}

export function MemberDelete(parameter) {
    return request({
        url: MemberApi.MemberDelete,
        method: 'post',
        data: parameter
    })
}

export function MemberBalance(parameter) {
    return request({
        url: MemberApi.MemberBalance,
        method: 'get',
        params: parameter
    })
}
