import request from "@/utils/request";

const userApi = {
  tagList: "/admin/AdminTag/tagList", // 标签列表
  tagInfo: "/admin/AdminTag/tagInfo", //  标签详情
  tagDisable: "/admin/AdminTag/tagDisable", // 标签是否禁用
  tagAdd: "/admin/AdminTag/tagAdd", // 标签添加
  tagEdit: "/admin/AdminTag/tagEdit", // 标签修改
  tagDele: "/admin/AdminTag/tagDele", // 标签删除
};

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
// 标签列表
export function tagList(parameter) {
  return request({
    url: userApi.tagList,
    method: "get",
    params: parameter,
  });
}
// 标签列表
export function tagInfo(parameter) {
  return request({
    url: userApi.tagInfo,
    method: "get",
    params: parameter,
  });
}
// 标签是否禁用
export function tagDisable(parameter) {
  return request({
    url: userApi.tagDisable,
    method: "post",
    data: parameter,
  });
}
// 添加标签
export function tagAdd(parameter) {
  return request({
    url: userApi.tagAdd,
    method: "post",
    data: parameter,
  });
}
// 修改标签
export function tagEdit(parameter) {
  return request({
    url: userApi.tagEdit,
    method: "post",
    data: parameter,
  });
}
// 修改标签
export function tagDele(parameter) {
  return request({
    url: userApi.tagDele,
    method: "post",
    data: parameter,
  });
}
