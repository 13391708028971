<template>
  <div class="u-calendar" style="user-select:none">
    <div
      class="flex items-center justify-center u-calendar__action u-row-center "
    >
      <div class="u-calendar__action__icon">
        <!-- <u-icon
          v-if="changeYear"
          name="arrow-left-double"
          :color="yearArrowColor"
          @click="changeYearHandler(0)"
        ></u-icon> -->
        <a-icon
          type="double-left"
          v-if="changeYear"
          name="arrow-left-double"
          :style="{ fontSize: '16px', color: yearArrowColor }"
          @click="changeYearHandler(0)"
        />
      </div>
      <div class="pl-1 u-calendar__action__icon">
        <!-- <u-icon
          v-if="changeMonth"
          name="arrow-left"
          :color="monthArrowColor"
          @click="changeMonthHandler(0)"
        ></u-icon> -->
        <a-icon
          type="left"
          v-if="changeMonth"
          name="arrow-left"
          :style="{ fontSize: '16px', color: monthArrowColor }"
          @click="changeMonthHandler(0)"
        />
      </div>
      <div
        class="flex items-center px-2 pt-1 text-base font-bold text-black u-calendar__action__text"
      >
        {{ showTitle }}
      </div>
      <div class="pr-1 u-calendar__action__icon">
        <!-- <u-icon
          v-if="changeMonth"
          name="arrow-right"
          :color="monthArrowColor"
          @click="changeMonthHandler(1)"
        ></u-icon> -->
        <a-icon
          type="right"
          v-if="changeMonth"
          name="arrow-right"
          :style="{ fontSize: '16px', color: monthArrowColor }"
          @click="changeMonthHandler(1)"
        />
      </div>
      <div class="u-calendar__action__icon">
        <a-icon
          type="double-right"
          v-if="changeYear"
          name="arrow-right-double"
          :style="{ fontSize: '16px', color: yearArrowColor }"
          @click="changeYearHandler(1)"
        />
        <!-- <u-icon
          v-if="changeYear"
          name="arrow-right-double"
          :color="yearArrowColor"
          @click="changeYearHandler(1)"
        ></u-icon> -->
      </div>
    </div>
    <div class="my-1 u-calendar__week-day">
      <div
        class="text-black u-calendar__week-day__text"
        v-for="(item, index) in weekDayZh"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="u-calendar__content">
      <!-- 前置空白部分 -->
      <template>
        <div
          class="u-calendar__content__item"
          v-for="(item, index) in weekdayArr"
          :key="index + 'none'"
        ></div>
      </template>
      <div
        class="u-calendar__content__item"
        :class="{
          'u-hover-class': openDisAbled(year, month, index + 1),
          'u-calendar__content--start-date':
            (mode == 'range' && startDate == `${year}-${month}-${index + 1}`) ||
            mode == 'date',
          'u-calendar__content--end-date':
            (mode == 'range' && endDate == `${year}-${month}-${index + 1}`) ||
            mode == 'date',
        }"
        :style="{ backgroundColor: getColor(index, 1) }"
        v-for="(item, index) in daysArr"
        :key="index"
        @click="dateClick(index)"
      >
        <div
          class="u-calendar__content__item__inner"
          :style="{ color: getColor(index, 2) }"
        >
          <div>{{ index + 1 }}</div>
          <div class="text-xs text-red" v-if="modelObject[index + 1]">已满</div>
        </div>
        <div
          class="u-calendar__content__item__tips"
          :style="{ color: activeColor }"
          v-if="
            mode == 'range' &&
              startDate == `${year}-${month}-${index + 1}` &&
              startDate != endDate
          "
        >
          {{ startText }}
        </div>
        <div
          class="u-calendar__content__item__tips"
          :style="{ color: activeColor }"
          v-if="mode == 'range' && endDate == `${year}-${month}-${index + 1}`"
        >
          {{ endText }}1
        </div>
      </div>
    </div>
    <!-- <udiv-toast ref="uToast" /> -->
  </div>
</template>
<script>
/**
 * calendar 日历
 * @description 此组件用于单个选择日期，范围选择日期等，日历被包裹在底部弹起的容器中。
 * @tutorial http://udivui.com/components/calendar.html
 * @property {String} mode 选择日期的模式，date-为单个日期，range-为选择日期范围
 * @property {Boolean} v-model 布尔值变量，用于控制日历的弹出与收起
 * @property {Boolean} safe-area-inset-bottom 是否开启底部安全区适配(默认false)
 * @property {Boolean} change-year 是否显示顶部的切换年份方向的按钮(默认true)
 * @property {Boolean} change-month 是否显示顶部的切换月份方向的按钮(默认true)
 * @property {String Number} max-year 可切换的最大年份(默认2050)
 * @property {String Number} min-year 最小可选日期(默认1950)
 * @property {String Number} min-date 可切换的最小年份(默认1950-01-01)
 * @property {String Number} max-date 最大可选日期(默认当前日期)
 * @property {String Number} 弹窗顶部左右两边的圆角值，单位rpx(默认20)
 * @property {Boolean} mask-close-able 是否允许通过点击遮罩关闭日历(默认true)
 * @property {String} month-arrow-color 月份切换按钮箭头颜色(默认#606266)
 * @property {String} year-arrow-color 年份切换按钮箭头颜色(默认#909399)
 * @property {String} color 日期字体的默认颜色(默认#303133)
 * @property {String} active-bg-color 起始/结束日期按钮的背景色(默认#2979ff)
 * @property {String Number} z-index 弹出时的z-index值(默认10075)
 * @property {String} active-color 起始/结束日期按钮的字体颜色(默认#ffffff)
 * @property {String} range-bg-color 起始/结束日期之间的区域的背景颜色(默认rgba(41,121,255,0.13))
 * @property {String} range-color 选择范围内字体颜色(默认#2979ff)
 * @property {String} start-text 起始日期底部的提示文字(默认 '开始')
 * @property {String} end-text 结束日期底部的提示文字(默认 '结束')
 * @property {String} btn-type 底部确定按钮的主题(默认 'primary')
 * @property {String} toolTip 顶部提示文字，如设置名为tooltip的slot，此参数将失效(默认 '选择日期')
 * @property {Boolean} closeable 是否显示右上角的关闭图标(默认true)
 * @example <u-calendar v-model="show" :mode="mode"></u-calendar>
 */
import { getDate } from "@/api/collect";
export default {
  name: "u-calendar",
  props: {
    millType: {
      type: Number,
      default: 1,
    },
    robot: {
      type: [Number, String],
      require: true,
    },
    safeAreaInsetBottom: {
      type: Boolean,
      default: false,
    },
    // 是否允许通过点击遮罩关闭Picker
    maskCloseAble: {
      type: Boolean,
      default: true,
    },
    // 通过双向绑定控制组件的弹出与收起
    value: {
      type: Boolean,
      default: false,
    },
    // 弹出的z-index值
    zIndex: {
      type: [String, Number],
      default: 0,
    },
    // 是否允许切换年份
    changeYear: {
      type: Boolean,
      default: true,
    },
    // 是否允许切换月份
    changeMonth: {
      type: Boolean,
      default: true,
    },
    // date-单个日期选择，range-开始日期+结束日期选择
    mode: {
      type: String,
      default: "date",
    },
    // 可切换的最大年份
    maxYear: {
      type: [Number, String],
      default: 2050,
    },
    // 可切换的最小年份
    minYear: {
      type: [Number, String],
      default: 1950,
    },
    // 最小可选日期(不在范围内日期禁用不可选)
    minDate: {
      type: [Number, String],
      default: "1950-01-01",
    },
    /**
     * 最大可选日期
     * 默认最大值为今天，之后的日期不可选
     * 2030-12-31
     * */
    maxDate: {
      type: [Number, String],
      default: "",
    },
    // 弹窗顶部左右两边的圆角值
    borderRadius: {
      type: [String, Number],
      default: 20,
    },
    // 月份切换按钮箭头颜色
    monthArrowColor: {
      type: String,
      default: "#606266",
    },
    // 年份切换按钮箭头颜色
    yearArrowColor: {
      type: String,
      default: "#909399",
    },
    // 默认日期字体颜色
    color: {
      type: String,
      default: "#303133",
    },
    // 选中|起始结束日期背景色
    activeBgColor: {
      type: String,
      default: "#294DEA",
    },
    // 选中|起始结束日期字体颜色
    activeColor: {
      type: String,
      default: "#ffffff",
    },
    // 范围内日期背景色
    rangeBgColor: {
      type: String,
      default: "#294DEA",
    },
    // 范围内日期字体颜色
    rangeColor: {
      type: String,
      default: "#294DEA",
    },
    // mode=range时生效，起始日期自定义文案
    startText: {
      type: String,
      default: "开始",
    },
    // mode=range时生效，结束日期自定义文案
    endText: {
      type: String,
      default: "结束",
    },
    //按钮样式类型
    btnType: {
      type: String,
      default: "#294DEA",
    },
    // 当前选中日期带选中效果
    isActiveCurrent: {
      type: Boolean,
      default: true,
    },
    // 切换年月是否触发事件 mode=date时生效
    isChange: {
      type: Boolean,
      default: false,
    },
    // 是否显示右上角的关闭图标
    closeable: {
      type: Boolean,
      default: true,
    },
    // 顶部的提示文字
    toolTip: {
      type: String,
      default: "选择日期",
    },
  },
  data() {
    return {
      // 星期几,值为1-7
      weekday: 1,
      weekdayArr: [],
      // 当前月有多少天
      days: 0,
      daysArr: [],
      showTitle: "",
      year: 2020,
      month: 0,
      day: 0,
      startYear: 0,
      startMonth: 0,
      startDay: 0,
      endYear: 0,
      endMonth: 0,
      endDay: 0,
      today: "",
      activeDate: "",
      startDate: "",
      endDate: "",
      isStart: true,
      min: null,
      max: null,
      weekDayZh: ["日", "一", "二", "三", "四", "五", "六"],
      modelObject: {},
    };
  },
  computed: {
    dataChange() {
      return `${this.mode}-${this.minDate}-${this.maxDate}`;
    },
    uZIndex() {
      // 如果用户有传递z-index值，优先使用
      return this.zIndex ? this.zIndex : this.$u.zIndex.popup;
    },
  },
  watch: {
    dataChange(val) {
      this.init();
    },
    robot: function() {
      console.log("机器人id改变了");
      let date = new Date();
      this.queryDate(this.createDate(date));
    },
  },
  created() {
    this.init();
    // let date = new Date();
    // this.queryDate(this.createDate(date))
  },
  methods: {
    getColor(index, type) {
      let color = type == 1 ? "" : this.color;
      let day = index + 1;
      let date = `${this.year}-${this.month}-${day}`;
      let timestamp = new Date(date.replace(/\-/g, "/")).getTime();
      let start = this.startDate.replace(/\-/g, "/");
      let end = this.endDate.replace(/\-/g, "/");
      if (
        (this.isActiveCurrent && this.activeDate == date) ||
        this.startDate == date ||
        this.endDate == date
      ) {
        color = type == 1 ? this.activeBgColor : this.activeColor;
      } else if (
        this.endDate &&
        timestamp > new Date(start).getTime() &&
        timestamp < new Date(end).getTime()
      ) {
        color = type == 1 ? this.rangeBgColor : this.rangeColor;
      }
      return color;
    },
    init() {
      let now = new Date();
      this.year = now.getFullYear();
      this.month = now.getMonth() + 1;
      this.day = now.getDate();
      this.today = `${now.getFullYear()}-${now.getMonth() +
        1}-${now.getDate()}`;
      this.activeDate = this.today;
      this.min = this.initDate(this.minDate);
      this.max = this.initDate(this.maxDate || this.today);
      this.startDate = "";
      this.startYear = 0;
      this.startMonth = 0;
      this.startDay = 0;
      this.endYear = 0;
      this.endMonth = 0;
      this.endDay = 0;
      this.endDate = "";
      this.isStart = true;
      this.changeData();
    },
    //日期处理
    initDate(date) {
      let fdate = date.split("-");
      return {
        year: Number(fdate[0] || 1920),
        month: Number(fdate[1] || 1),
        day: Number(fdate[2] || 1),
      };
    },
    openDisAbled: function(year, month, day) {
      let bool = true;
      let date = `${year}/${month}/${day}`;
      // let today = this.today.replace(/\-/g, '/');
      let min = `${this.min.year}/${this.min.month}/${this.min.day}`;
      let max = `${this.max.year}/${this.max.month}/${this.max.day}`;
      let timestamp = new Date(date).getTime();
      if (
        timestamp >= new Date(min).getTime() &&
        timestamp <= new Date(max).getTime()
      ) {
        bool = false;
      }
      return bool;
    },
    generateArray: function(start, end) {
      return Array.from(new Array(end + 1).keys()).slice(start);
    },
    formatNum: function(num) {
      return num < 10 ? "0" + num : num + "";
    },
    //一个月有多少天
    getMonthDay(year, month) {
      let days = new Date(year, month, 0).getDate();
      this.modelObject = {};
      for (let i = 1; i <= days; i++) {
        this.$set(this.modelObject, i, false);
      }
      return days;
    },
    getWeekday(year, month) {
      let date = new Date(`${year}/${month}/01 00:00:00`);
      return date.getDay();
    },
    checkRange(year) {
      let overstep = false;
      if (year < this.minYear || year > this.maxYear) {
        uni.showToast({
          title: "日期超出范围啦~",
          icon: "none",
        });
        overstep = true;
      }
      return overstep;
    },
    changeMonthHandler(isAdd) {
      if (isAdd) {
        let month = this.month + 1;
        let year = month > 12 ? this.year + 1 : this.year;
        if (!this.checkRange(year)) {
          this.month = month > 12 ? 1 : month;
          this.year = year;
          this.changeData();
        }
      } else {
        let month = this.month - 1;
        let year = month < 1 ? this.year - 1 : this.year;
        if (!this.checkRange(year)) {
          this.month = month < 1 ? 12 : month;
          this.year = year;
          this.changeData();
        }
      }
    },
    changeYearHandler(isAdd) {
      let year = isAdd ? this.year + 1 : this.year - 1;
      if (!this.checkRange(year)) {
        this.year = year;
        this.changeData();
      }
    },
    changeData() {
      this.days = this.getMonthDay(this.year, this.month);
      this.daysArr = this.generateArray(1, this.days);
      this.weekday = this.getWeekday(this.year, this.month);
      this.weekdayArr = this.generateArray(1, this.weekday);
      this.showTitle = `${this.year}年${this.month}月`;
      if (this.mode == "date") {
        this.btnFix(true);
      }

      let flag = this.judgeDate(this.year, this.month);

      if (flag) {
        let month = this.month < 10 ? "0" + this.month : this.month;
        let date = this.year + "-" + month;
        this.queryDate(date);
      }
    },
    dateClick: function(day) {
      console.log("day", day);
      day += 1;
      if (!this.openDisAbled(this.year, this.month, day)) {
        this.day = day;
        let date = `${this.year}-${this.month}-${day}`;

        let flag = this.decideDate(this.year, this.month, this.day);
        let isfull = this.modelObject[this.day];
        if (isfull) {
          this.$refs.uToast.show({
            title: "不能预约该日期",
            type: "warning",
          });
          return;
        }

        if (this.mode == "date") {
          this.activeDate = date;
        } else {
          let compare =
            new Date(date.replace(/\-/g, "/")).getTime() <
            new Date(this.startDate.replace(/\-/g, "/")).getTime();
          if (this.isStart || compare) {
            this.startDate = date;
            this.startYear = this.year;
            this.startMonth = this.month;
            this.startDay = this.day;
            this.endYear = 0;
            this.endMonth = 0;
            this.endDay = 0;
            this.endDate = "";
            this.activeDate = "";
            this.isStart = false;
          } else {
            this.endDate = date;
            this.endYear = this.year;
            this.endMonth = this.month;
            this.endDay = this.day;
            this.isStart = true;
          }
        }
      }
      this.btnFix();
    },
    close() {
      // 修改通过v-model绑定的父组件变量的值为false，从而隐藏日历弹窗
      this.$emit("input", false);
    },
    getWeekText(date) {
      date = new Date(`${date.replace(/\-/g, "/")} 00:00:00`);
      let week = date.getDay();
      return "星期" + ["日", "一", "二", "三", "四", "五", "六"][week];
    },
    btnFix() {
      // if (!show) {
      // 	this.close();
      // }
      if (this.mode == "date") {
        let arr = this.activeDate.split("-");
        let year = this.isChange ? this.year : Number(arr[0]);
        let month = this.isChange ? this.month : Number(arr[1]);
        let day = this.isChange ? this.day : Number(arr[2]);
        //当前月有多少天
        // let days = this.getMonthDay(year, month);
        let result = `${year}-${this.formatNum(month)}-${this.formatNum(day)}`;
        let weekText = this.getWeekText(result);
        let isToday = false;
        if (`${year}-${month}-${day}` == this.today) {
          //今天
          isToday = true;
        }
        this.$emit("change", {
          year: year,
          month: month,
          day: day,
          result: result,
          week: weekText,
          isToday: isToday,
          // switch: show //是否是切换年月操作
        });
      } else {
        if (!this.startDate || !this.endDate) return;
        let startMonth = this.formatNum(this.startMonth);
        let startDay = this.formatNum(this.startDay);
        let startDate = `${this.startYear}-${startMonth}-${startDay}`;
        let startWeek = this.getWeekText(startDate);

        let endMonth = this.formatNum(this.endMonth);
        let endDay = this.formatNum(this.endDay);
        let endDate = `${this.endYear}-${endMonth}-${endDay}`;
        let endWeek = this.getWeekText(endDate);
        this.$emit("change", {
          startYear: this.startYear,
          startMonth: this.startMonth,
          startDay: this.startDay,
          startDate: startDate,
          startWeek: startWeek,
          endYear: this.endYear,
          endMonth: this.endMonth,
          endDay: this.endDay,
          endDate: endDate,
          endWeek: endWeek,
        });
      }
    },
    // 查询日期（该日期是否可预约）
    queryDate(date, millType) {
      if (!this.robot) {
        return;
      }
      getDate({
        model: millType ? millType : this.millType,
        robot_id: this.robot,
        date,
      })
        .then((r) => {
          let str = "";
          let arr = null;
          let attr = "";
          this.modelObject = {};
          r.data.forEach((item) => {
            str = item.date.substr(-2);
            arr = str.split("");
            arr[0] == "0" ? (attr = arr[1]) : (attr = str);
            this.modelObject[attr] = item.status == 1 ? true : false;
          });
          // console.log(this.day)
          this.$emit("selective", this.modelObject[this.day]);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 生成日期参数
    createDate(date) {
      let Y = date.getFullYear();
      let M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      return Y + "-" + M;
    },
    // 判断展示的日期是否是已经过去的时间（过去的时间不可选择，不可预约，不用发送请求，不用判断）
    judgeDate(year, month) {
      let date = new Date();
      let Y = date.getFullYear();
      let M = date.getMonth() + 1;
      if (year < Y) {
        return false;
      } else if (year == Y) {
        return month < M ? false : true;
      } else {
        return true;
      }
    },
    decideDate(year, month, day) {
      let date = new Date();
      let Y = date.getFullYear();
      let M = date.getMonth() + 1;
      let D = date.getDate();
      if (year < Y) {
        return false;
      } else if (year == Y) {
        if (month < M) {
          return false;
        } else if (month == M) {
          if (day < D) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    judgeFull(index) {
      let day = index < 10 ? "0" + index : index;
      return this.modelObject[index];
    },
  },
};
</script>

<style scoped lang="less">
/*自定义的样式*/

/*自定义的样式*/

.u-calendar {
  width: 698upx;
  //   color: #3db859;

  &__header {
    width: 100%;
    box-sizing: border-box;
    font-size: 30rpx;
    background-color: #fff;
    // color: #3db859;

    &__text {
      margin-top: 30rpx;
      padding: 0 60rpx;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__action {
    padding: 40rpx 0 40rpx 0;

    &__icon {
      margin: 0 16rpx;
    }

    &__text {
      padding: 0 16rpx;
      //   color: #3db859;
      font-size: 32rpx;
      line-height: 32rpx;
      font-weight: bold;
    }
  }

  &__week-day {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    overflow: hidden;

    &__text {
      flex: 1;
      text-align: center;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;

    &--end-date {
      border-top-right-radius: 8rpx;
      border-bottom-right-radius: 8rpx;
    }

    &--start-date {
      border-top-left-radius: 8rpx;
      border-bottom-left-radius: 8rpx;
    }

    &__item {
      width: 14.2857%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      overflow: hidden;
      position: relative;
      z-index: 2;
      border-radius: 4px;
      cursor: pointer;
      &__inner {
        height: 84rpx;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 32rpx;
        position: relative;
        border-radius: 50%;

        &__desc {
          width: 100%;
          font-size: 24rpx;
          line-height: 24rpx;
          transform: scale(0.75);
          transform-origin: center center;
          position: absolute;
          left: 0;
          text-align: center;
          bottom: 2rpx;
        }
      }

      &__tips {
        width: 100%;
        font-size: 24rpx;
        line-height: 24rpx;
        position: absolute;
        left: 0;
        transform: scale(0.8);
        transform-origin: center center;
        text-align: center;
        bottom: 8rpx;
        z-index: 2;
      }
    }

    &__bg-month {
      position: absolute;
      font-size: 130px;
      line-height: 130px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #e4e7ed;
      z-index: 1;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 0 40rpx 30rpx;
    box-sizing: border-box;
    font-size: 24rpx;
    color: #3db859;

    &__choose {
      height: 50rpx;
    }

    &__btn {
      width: 100%;
    }
  }
}
</style>
